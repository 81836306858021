/* App.css */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body, html {
    height: 100%;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f9;
  }
  
  .App {
    text-align: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .App-logo {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin-bottom: 20px;
    transition: transform 0.3s;
  }
  
  .coming-soon {
    font-size: 2rem;
    color: #333;
  }
  
  /* Responsiveness */
  @media (max-width: 768px) {
    .App-logo {
      width: 70%;
    }
    
    .coming-soon {
      font-size: 1.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .App-logo {
      width: 90%;
    }
    
    .coming-soon {
      font-size: 1.2rem;
    }
  }